.App {
  overflow-x: hidden;
  padding: 0px;
  z-index: 1;
}

.App .navbar-brand {
  font-weight: bold;
}

.logo {
  max-width: 100px;
}

.darkpanel {
  background-color: #f8f9fa;
  padding: 4em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.footer {
  background-color: #f8f9fa;
  padding: 2em;
}

.threecol {
  min-width: 50vw;
  max-width: 90vw;
  padding-left: 10px;
  padding-bottom: 10px;
}

.threecol li a {
	display: block;
	clear: both;
	line-height: 1.428571429;
  color: #333;
  font-size: 15px;
  padding: 5px;
	white-space: normal;
}

.twocol {
  min-width: 30vw;
  max-width: 90vw;
  padding-left: 10px;
  padding-bottom: 10px;
}

.twocol li a {
	display: block;
	clear: both;
	line-height: 1.428571429;
  color: #333;
  font-size: 15px;
  padding: 5px;
	white-space: normal;
}

.lightpanel {
  padding: 4em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.panelimg {
  display: block;
  padding: auto;
  max-width:100%;
  max-height:100%;
}

.panelimg-blue {
  display: block;
  padding: 10px;
  max-width:100%;
  max-height:100%;
  background-color:#3d4184;
  border-radius: 10px;
}

.docsbox {
  position: absolute;
  z-index: 100;
  margin-top: -75px;
  background-color: white;
  width: 99.9vw;
  overflow-x: hidden;
}

.leftmenu {
  padding-left: 10px;
  position: fixed;
  max-height: 100vh;
  max-width: 15vw;
  overflow-y: scroll;
  overflow-x: auto;
}

.leftmenucontainer {
  min-height: 300vh;
}

.btn-blue {
  background-color:#3d4184;
  color: white;
  margin: 8px
}

.absolutebanner {
  position:absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100vw;
  overflow:hidden;
  max-width:100%;
}

.btn-green {
  background-color:#2eb596;
}

.dark {
  background-color: #2d3134 !important;
  color: white;
}

.darkcol {
  background-color: #2d3134 !important;
  color: white;
  padding-bottom: 300px;
}

.nopadding {
    padding: 0px;
}

.centered {
  margin: auto;
  padding: 3px;
  padding-bottom: 20px;
  text-align: center;
}

.b-documentation_button a {
  display: inline-block !important;
  padding: 0px !important;
}
.b-api_page .navbar-nav {
  margin: 7.5px -15px !important;
}

.b-api_page .b-api_page_mobile_logo {
  display: none;
}

.b-api_page .navbar-default{
  background-color: #ffffff;
  border: 0;
}

@media (max-width: 998px) and (min-width: 768px){
  .b-api_page .leftmenucontainer .leftmenu {
    max-width: 20vw;
  }
}

@media (max-width: 767px) and (min-width: 320px){
  .b-api_page{
    margin-right: 0;
    margin-left: 0;
    width: 100% !important;
  }
  .b-api_page .leftmenucontainer{
    min-height: 100%;
  }
  .b-api_page .navbar-default{
    background-color: #f8f8f8;
    border: 1px solid #e7e7e7;
  }
  .b-api_page .leftmenucontainer .navbar-header .b-api_page_mobile_logo {
    display: inline-block;
  }
  .b-api_page .leftmenucontainer .leftmenu .navbar-brand {
    display: none;
  }
  .b-api_page .leftmenucontainer .leftmenu {
    margin-top: -50px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
  }
}
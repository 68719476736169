.Home .lander {
  padding: 80px 0;
  text-align: center;
  max-width: 600px;
}

.jumbotron-custom {
  background-color: transparent;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .notes p {
  color: #666;
}

.b-api_page .navbar-nav {
  margin: 7.5px -15px !important;
}

.b-api_page .b-api_page_mobile_logo {
  display: none;
}

.b-api_page .navbar-default{
  background-color: #ffffff;
  border: 0;
}

@media (max-width: 998px) and (min-width: 768px){
  .b-api_page .leftmenucontainer .leftmenu {
    max-width: 20vw;
  }
}

@media (max-width: 767px) and (min-width: 320px){
  .b-api_page{
    margin-right: 0;
    margin-left: 0;
    width: 100% !important;
  }
  .b-api_page .leftmenucontainer{
    min-height: 100%;
  }
  .b-api_page .navbar-default{
    background-color: #f8f8f8;
    border: 1px solid #e7e7e7;
  }
  .b-api_page .leftmenucontainer .navbar-header .b-api_page_mobile_logo {
    display: inline-block;
  }
  .b-api_page .leftmenucontainer .leftmenu .navbar-brand {
    display: none;
  }
  .b-api_page .leftmenucontainer .leftmenu {
    margin-top: -50px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
  }
}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.Notes form {
  padding-bottom: 15px;
}

.Notes form textarea {
  height: 300px;
  font-size: 24px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.NewNote form {
  padding-bottom: 15px;
}

.NewNote form textarea {
  height: 300px;
  font-size: 24px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.BillingForm .card-field {
    margin-bottom: 15px;
    background-color: white;
    padding: 11px 16px;
    border-radius: 6px;
    border: 1px solid #CCC;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    line-height: 1.3333333;
  }
  
.BillingForm .card-field.StripeElement--focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    border-color: #66AFE9;
  }
@media all and (min-width: 480px) {
    .Settings {
      padding: 60px 0;
    }
  
    .Settings form {
      margin: 0 auto;
      max-width: 480px;
    }
  }
.App {
  overflow-x: hidden;
  padding: 0px;
  z-index: 1;
}

.App .navbar-brand {
  font-weight: bold;
}

.logo {
  max-width: 100px;
}

.darkpanel {
  background-color: #f8f9fa;
  padding: 4em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.footer {
  background-color: #f8f9fa;
  padding: 2em;
}

.threecol {
  min-width: 50vw;
  max-width: 90vw;
  padding-left: 10px;
  padding-bottom: 10px;
}

.threecol li a {
	display: block;
	clear: both;
	line-height: 1.428571429;
  color: #333;
  font-size: 15px;
  padding: 5px;
	white-space: normal;
}

.twocol {
  min-width: 30vw;
  max-width: 90vw;
  padding-left: 10px;
  padding-bottom: 10px;
}

.twocol li a {
	display: block;
	clear: both;
	line-height: 1.428571429;
  color: #333;
  font-size: 15px;
  padding: 5px;
	white-space: normal;
}

.lightpanel {
  padding: 4em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.panelimg {
  display: block;
  padding: auto;
  max-width:100%;
  max-height:100%;
}

.panelimg-blue {
  display: block;
  padding: 10px;
  max-width:100%;
  max-height:100%;
  background-color:#3d4184;
  border-radius: 10px;
}

.docsbox {
  position: absolute;
  z-index: 100;
  margin-top: -75px;
  background-color: white;
  width: 99.9vw;
  overflow-x: hidden;
}

.leftmenu {
  padding-left: 10px;
  position: fixed;
  max-height: 100vh;
  max-width: 15vw;
  overflow-y: scroll;
  overflow-x: auto;
}

.leftmenucontainer {
  min-height: 300vh;
}

.btn-blue {
  background-color:#3d4184;
  color: white;
  margin: 8px
}

.absolutebanner {
  position:absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100vw;
  overflow:hidden;
  max-width:100%;
}

.btn-green {
  background-color:#2eb596;
}

.dark {
  background-color: #2d3134 !important;
  color: white;
}

.darkcol {
  background-color: #2d3134 !important;
  color: white;
  padding-bottom: 300px;
}

.nopadding {
    padding: 0px;
}

.centered {
  margin: auto;
  padding: 3px;
  padding-bottom: 20px;
  text-align: center;
}

.b-documentation_button a {
  display: inline-block !important;
  padding: 0px !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", serif;
  font-weight: lighter;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

